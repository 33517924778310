import ShanghaiRSNTable from "@/views/ShanghaiManifest/components/ShanghaiRSNTable.vue";
import AddManifestContactDialog from "@/views/ShanghaiManifest/components/contacts/AddManifestContactDialog.vue";

export default {
    components: {
        AddManifestContactDialog,
        ShanghaiRSNTable,
    },
    data() {
        return {
            editItem: {},
            type: {
                title: '通知人',
                code: 2
            },
            showAddDialog: false,
            tableHeaders: this.RESETTABLE.shanghaiSN,
        };
    },
    created() {
    },
    methods: {
        refreshList() {
            this.$refs.ShanghaiRSNTable.getList();
        },
        edit(item) {
            this.editItem = item;
            this.showAddDialog = true;
        }
    },
};
