<template>
  <div>
    <el-dialog :visible.sync="visible" append-to-body width="66%" @close="closeDialog"
               :close-on-click-modal="false" :title="typeVal.title">
      <div class="rsn_div">
        <div class="rsn_sub_div">
          <div class="title_div">
            <span class="title">发货人</span>
          </div>
          <AddManifestContactForm :type-val="type1" ref="contactForm1"
                                  :country-list="countryList"></AddManifestContactForm>
        </div>
        <div class="rsn_sub_div">
          <div class="title_div">
            <span class="title">收货人</span>
            <span class="btn" @click="copy2Notice">同步到通知人</span>
          </div>
          <AddManifestContactForm :type-val="type2" ref="contactForm2"
                                  :country-list="countryList"></AddManifestContactForm>
        </div>
        <div class="rsn_sub_div">
          <div class="title_div">
            <span class="title">通知人</span>
          </div>
          <AddManifestContactForm :type-val="type3" ref="contactForm3"
                                  :country-list="countryList"></AddManifestContactForm>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" style="text-align:center;">
                <div style="margin:0 auto;font-size:15px;">自命名：</div>
                <el-input v-enterNextInput width="50"
                          v-model="nickName"
                          placeholder="请输入简称,方便您检索"></el-input>
                <div style="margin:0 auto;">
                    <el-button @click="closeDialog">取 消</el-button>
                    <el-button type="primary" @click="save">确 定</el-button>
                </div>
            </span>
    </el-dialog>
  </div>
</template>
<script>
import AddManifestContactForm from "./AddManifestContactForm.vue";
import {getAllCountryCode, insertRSNModule} from "../../../../api/shanghaiManifest";

export default {
  name: "AddManifestRSNDialog",
  components: {AddManifestContactForm},
  props: {
    item: {
      type: Object,
      default: () => {
      }
    },
    isShow: {
      type: Boolean,
      default: false
    },
    typeVal: {
      type: Object,
      default: () => {
      }
    }
  },
  watch: {
    typeVal(newVal, oldVal) {
      this.form.type = newVal.code
    },
    isShow(newVal, oldVal) {
      this.visible = newVal;
    }
  },
  data() {
    return {
      type1: {"title": "发货人", code: 0},
      type2: {"title": "收货人", code: 1},
      type3: {"title": "通知人", code: 2},
      visible: false,
      countryList: [],
      nickName: '',
    }
  },
  methods: {
    init() {
      this.getAllCountryData();
    },
    getDetail() {
      if (this.item&&this.item.id){

      }

    },
    getAllCountryData() {
      getAllCountryCode().then(({data}) => {
        this.countryList = data.data.map(x => {
          return {
            id: x.id,
            label: x.code + '-' + x.name,
            value: x.code + '-' + x.name,
          }
        })
      })
    },
    closeDialog() {
      this.$emit("close");
    },
    save() {
      let data = {
        nickName: this.nickName,
        contacts: [
          this.$refs.contactForm1.form,
          this.$refs.contactForm2.form,
          this.$refs.contactForm3.form,
        ]
      }
      insertRSNModule(data).then((res) => {
        if (res.data.status) {
          this.$message.success("保存成功");
          this.$emit('refresh');
          this.closeDialog();
        } else {
          this.$message.error("保存失败");
        }
      })
    },
    //将收货人信息同步到通知人
    copy2Notice() {
      this.$refs.contactForm3.form = JSON.parse(JSON.stringify(this.$refs.contactForm2.form));
    }
  },
  mounted() {
    this.init();

  }
}
</script>

<style scoped lang="stylus">
.rsn_div {
  display: flex;
  justify-content: space-between;
}

.rsn_sub_div {
  padding: 1rem;
  border: 1px solid #dcdcdc;

  .title_div {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #dcdcdc;
  }

  .btn {
    cursor: pointer;
    color: var(--BLUE-409eff);
  }

  .title {
    text-align: center;
  }
}
</style>
